@import "@aws-amplify/ui-react/styles.css";

.App {
  text-align: center;
  background-color: black;
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.breadcrumb {
  background-color: black !important;
}

:root {
  --amplify-primary-color: #9f1f63;
  --amplify-primary-tint: #a51a64;
  --amplify-primary-shade: #b91d71;
}

.btn-grad {
  background-image: linear-gradient(
    90deg,
    rgba(197, 2, 129, 1) 0%,
    rgba(219, 21, 42, 1) 100%
  );
}

.btn-grad {
  transition: 0.5s;
  background-size: 200% auto;
  color: white !important;
}

.btn-grad:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.breadcrumb-color a {
  text-decoration: none;
  color: #c7c7c7;
}

.breadcrumb-color a:hover {
  text-decoration: none;
  color: white;
}

.breadcrumb-color-muted a {
  text-decoration: none;
  color: #6c757d;
}

.breadcrumb-color-muted a:hover {
  text-decoration: none;
  color: #c7c7c7;
}

.director-image {
  background-image: url("DirectorBG.png");
  background-repeat: no-repeat;
  background-position: 25% 90%;
  background-size: 80%;
  padding-bottom: 3.5%;
}

.iris-nav {
  color: #c7c7c7 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.iris-nav:hover {
  color: white !important;
}

.iris-nav:focus {
  color: white !important;
}

.iris-check input:checked {
  background-color: #9f1f63;
  border-color: white;
}

.iris-radio input:checked {
  background-color: #9f1f63;
  border-color: white;
}

.btn-action {
  background-color: transparent;
  color: white;
}

.btn-action:hover {
  background-color: #41464b;
  color: white;
}

.btn-link:hover {
  color: #9f1f63 !important;
}

.dropdown-dark {
  --bs-dropdown-link-active-bg: #292b2c;
}

.btn-black:active:focus:hover {
  color: white;
  background-color: #292b2c;
}
