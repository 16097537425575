.btn-header {
  background-color: white;
  color: #9f1f63;
  font-weight: bold;
}

.btn-header:hover {
  background-color: rgb(131, 128, 128);
  color: #9f1f63;
}
