.btn-navBar {
  background-image: linear-gradient(
    90deg,
    rgba(197, 2, 129, 1) 0%,
    rgba(219, 21, 42, 1) 100%
  );
  border-radius: 0px 0px 10px 10px;
  border: none;
  color: white;
  transition: 0.5s;
  background-size: 200% auto;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
}
